.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.search {
    width: 80%;
    padding: 10px;
    border: none;
    border-bottom: solid 1px lightgrey;
}

.search-btn {
    background-color: #0066CC;
    /* background-color: transparent; */
    border: none;
    padding: 0 20px 0 20px;
    border-radius: 5px;
    color: whitesmoke;
    font-weight: bolder;
    font-size: 30px;
    transform: rotate(180deg);
    margin-left: 5px;
    margin-right: 5px;

}

.search-btn:hover {
    opacity: .95;
}

.btn{
    background-color: #0066CC;
}

.it-header-wrapper{
    background-color: #0066CC !important;
}
.it-header-center-wrapper{
    background-color: #0066CC !important; 
}
.it-header-navbar-wrapper{
    background-color: #0066CC !important; 
}
.custom-navbar-toggler{
    background-color: #0066CC !important; 
}
.navbar{
    background-color: #0066CC !important; 
}




#dropdown-basic{
    background-color: #0066CC !important;
    border:none !important;
    outline: none !important;
}

.it-header-slim-wrapper {
    background: #004080;
    padding: 6.5px 18px;
}